@import url('./maintenance.css');

#index-header-banner {
    background: url('../images/header-banner.jpg') center center;
    background-size: cover;
}

@media (max-width: 1000px) {
	#index-header-banner {
        background: url('../images/header-banner_mobile.jpg') center center;
        background-size: cover;
    }
}

#usercount {
    font-weight: 700;
    font-size: 48px;
}

@media (max-width: 768px) {
    .video {
        line-height: 350px !important;
        min-height: 350px !important;
    }
}

.video {
    line-height: 700px;
    width: 100%;
    min-height: 100%;
    background: url('../images/video.png');
    background-size: cover;
    text-align: center;
}

.video img {cursor: pointer;}

.video .modal-body {
    line-height: normal;
}

.video-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
}

.video-container .col-md-6 {
    flex-grow: 1;
    max-width: 100%;
}

@media (max-width: 768px) {
    .stats {
        padding: 20px !important;
    }
}

.stats {
    height: auto;
    width: 100%;
    min-height: 700px;
    background: url('../images/stats.png');
    background-size: cover;
    padding: 100px;
}

.stats img {
    height: auto;
    width: 100%;
    max-width: 115px;
    max-height: 115px;
}

.stats .text {
    color: #033777;
    font-weight: 400;
    font-size: 20px;
    text-align: center;
    margin-top: 50px;
}

.stats .text span {
    font-weight: 300;
}

.stats .text img {
    margin-bottom: 20px;
    height: auto;
    width: 60px;
}

.stats .numbers {
    color: #033777;
    font-weight: 300;
    font-size: 32px;
    line-height: 40px;
    margin-top: 15px;
    text-align:center;
}

.partners {
    height: auto;
    width: 100%;
    padding: 40px;
    background: #fff;
}

.partners .item img {
    height: auto;
    width: auto;
}

.slider .slick-prev {
    left: 100px;
    z-index: 1;
}

.slider .slick-next {
    right: 100px;
    z-index: 1;
}

@media (max-width: 768px) {
    .slider .slick-prev {
        left: 20px !important;
        z-index: 1;
    }

    .slider .slick-next {
        right: 20px !important;
        z-index: 1;
    }
}

.slider .slick-prev:before,
.slider .slick-next:before {
    font-family: 'slick';
    font-size: 28px;
    line-height: 1;

    opacity: 0.5;
    color: #313131;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.miniszoveg {
 font-size:6pt;
 line-height:9pt
}
.kozlemeny {
 background:#fff
}
.mini {
 margin:0 auto;
 max-width:728px;
 padding:20px 10px;
}

#scroll-to-szolgaltatoi {
    background-color: rgba(0,0,0,0.35);
    transition: background-color 250ms;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4pt;
    position: fixed;
    top: 80px;
    right: 20px;
    width: 160px;
    padding: 18px 0px;
    cursor: pointer;
}

#scroll-to-szolgaltatoi:hover {
    background-color: rgba(0,0,0,0.65);
}

#scroll-to-szolgaltatoi > strong {
    text-transform: uppercase;
    color: white;
    text-align: center;
    font-size: 10pt;
}

#scroll-to-szolgaltatoi > .glyphicon {
    color: #8cc63f;
    font-size: 38pt;
}

@media (max-width: 768px) {
    #scroll-to-szolgaltatoi {
        display: none;
    }
}
