/*
 * Az eredeti slick 1.8.1 téma módosításai - kiemelve
 */
 .slick-prev:before {
  opacity: .5;
  color: #313131;
}


 .slick-next:before {
  opacity: .5;
  color: #313131;
}

.slick-slide {
  outline: 0;
}
