/*
 * Az eredeti bootstrap 3.1.1 téma módosításai - kiemelve
 */

 .close {
  filter: none;
  opacity: 1;
}

/*
 * Az eredeti bootstrap 5.1.3 téma módosításai - kiemelve
 */

 a {
  color: #337ab7;
  text-decoration: none;
}

a:focus,a:hover {
  color: #23527c;
}

.close {
    /* filter: none; */
    /* opacity: 1; */
    padding: 0;
    border: 0;
}

.text-info {
    color: #31708f !important;
}
